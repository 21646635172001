<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
    <!-- Brand logo-->
    <b-link class="brand-logo">
      <b-img
        fluid
        :src="imgLogo2"
        alt="Logo New X Coins"
        style="max-height: 8rem"
      />
    </b-link>
    <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img fluid :src="imgUrl" alt="Forgot password V2" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Email Verify -->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <div v-if="!loading">
            <div
              class="animate__animated animate__fadeIn"
              v-if="emailConfirmed"
            >
              <sweetalert-icon
                icon="success"
                class="animate__animated animate__backInDown"
              />
              <b-card-title class="mb-1 text-success">
                {{ $t("Seu e-mail foi confirmado!") }}
              </b-card-title>
              <b-card-text class="mb-2">
                {{
                  $t("Todos os recursos da sua conta agora estão liberados!")
                }}
              </b-card-text>
            </div>
            <div class="animate__animated animate__fadeIn" v-else>
              <sweetalert-icon
                icon="error"
                class="animate__animated animate__backInDown"
              />
              <b-card-title class="mb-1 text-danger">
                {{ $t("Erro ao confirmar e-mail!") }}
              </b-card-title>
              <b-card-text class="mb-2">
                {{
                  $t(
                    "Houve um problema na confirmação do seu e-mail, por favor, clique no link abaixo para receber um novo e-mail de confirmação."
                  )
                }}
              </b-card-text>
              <b-link
                class="alert-link text-warning"
                @click="sendConfirmationEmail"
                v-if="!emailSent"
              >
                {{ $t("Reenviar e-mail de confirmação") }}
              </b-link>
              <b-alert :show="emailSent" variant="success" class="mb-50">
                <div class="alert-body">
                  {{
                    $t(
                      "E-mail enviado! Confira em sua caixa de entrada o link para a confirmação."
                    )
                  }}
                </div>
              </b-alert>
            </div>
            <p class="text-center mt-2">
              <b-link to="/">
                <feather-icon icon="ChevronLeftIcon" />
                {{ $t("Voltar para a página inicial") }}
              </b-link>
            </p>
            <div class=" text-center">
            <div style="margin-top: 0.25rem">
              <span class="">
                {{ $t("Versão") }}</span>
              <span class="text-primary" style="margin-left: 0.3rem">{{
                appVersion
                }}</span>
            </div>
          </div>
          </div>
        </b-col>
      </b-col>
      <!-- /Email Verify-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ProjectLogo from "@/layouts/logo/LogoProjectX.vue";
import {
  BRow,
  BCol,
  BLink,
  BCardTitle,
  BCardText,
  BImg,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BSpinner,
  BAlert,
} from "bootstrap-vue";
import { required, email } from "@validations";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters, mapActions } from "vuex";
import { version } from "../../../package";
export default {
  components: {
    ProjectLogo,
    BRow,
    BCol,
    BLink,
    BImg,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
    BAlert,
  },
  data() {
    return {
      appVersion: version,
      userEmail: "",
      sideImg: require("@/assets/images/pages/email-verify.svg"),
      labelForgotPassword: this.$i18n.t("E-mail"),
      loading: false,
      emailError: "",
      emailSent: false,
      emailConfirmed: false,
      loading: true,

      // validation
      required,
      email,
    };
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/email-verify-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
    imgLogo() {
      if (store.state.appConfig.layout.skin === "dark") {
        return require("@/assets/images/logo/newxcoins_logo_light.png");
      } else {
        return require("@/assets/images/logo/newxcoins_logo_dark.png");
      }
    },
    imgLogo2() {
      return require("@/assets/images/logo/newxcoins_logo_light.png");
    },
  },
  mounted() {
    const routerConfirmeEmailAPI =
      this.$route.path +
      "?expires=" +
      this.$route.query.expires +
      "&signature=" +
      this.$route.query.signature;
    this.$store
      .dispatch("confirmEmailAPI", routerConfirmeEmailAPI)
      .then((response) => {
        this.emailConfirmed = true;
        this.loading = false;
        this.$store.dispatch("recoverUserData");
      })
      .catch((error) => {
        this.emailConfirmed = false;
        this.loading = false;
      });
  },
  methods: {
    ...mapActions(["confirmEmail"]),
    sendConfirmationEmail() {
      this.confirmEmail()
        .then((response) => {
          this.emailSent = true;
        })
        .catch((error) => {});
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
